<template>
    <div class="app-layout">
        <app-sidebar v-if="isAuthenticated && showSidebar" />

        <div class="app-layout__notifications" ref="notifications" />

        <div v-if="$slots.modal" class="app-layout__modal-wrapper" v-click-self="closeModal">
            <slot name="modal" />
        </div>

        <div class="app-layout__content-outer">
            <div class="app-layout__header">
                <logo-link />
            </div>

            <div v-if="$slots.aside" class="app-layout__aside-wrapper" v-click-self="closeAside">
                <div class="app-layout__aside">
                    <slot name="aside" />
                </div>
            </div>

            <scroll-container>
                <div class="app-layout__content">
                    <slot />
                </div>
            </scroll-container>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AppSidebar from '@/pages/layout/AppSidebar';
    import LogoLink from '@/components/LogoLink';
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'AppLayout',
        components: {
            AppSidebar,
            LogoLink,
            ScrollContainer,
        },
        props: {
            adjustWindowHeight: {
                type: Boolean,
                default: true,
            },
            showSidebar: {
                type: Boolean,
                default: true,
            },
        },
        computed: {
            ...mapGetters('auth', ['isAuthenticated']),
        },
        methods: {
            closeAside() {
                this.$emit('asideClosed');
            },
            closeModal() {
                this.$emit('modalClosed');
            },
        },
        mounted() {
            this.$toast.updateDefaults({
                container: this.$refs.notifications,
            });
        },
    };
</script>

<style lang="scss" scoped>
    .app-layout {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: hidden;
    }

    .app-layout__content-outer {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        min-height: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        ::v-deep {
            & > .scroll-container {
                flex: 1;

                & > .scroll-container__content {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .app-layout__header {
        padding: 1.5rem;
        background-color: #0f141e;
    }

    .app-layout__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 1.5rem;

        ::v-deep {
            > div:not(:first-child) {
                margin-top: 1.5rem;
            }

            .loader-wrapper {
                margin: auto;
            }
        }
    }

    .app-layout__modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;
        background-color: rgba(14, 17, 25, 0.9);
        z-index: 4;
    }

    .app-layout__aside-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(14, 17, 25, 0.9);
        z-index: 2;
    }

    .app-layout__aside {
        height: 100%;
        background-color: rgba(18, 24, 35, 0.8);
        box-shadow: 0 1px 64px rgba(0, 0, 0, 0.24);
        backdrop-filter: blur(10px);
    }

    .app-layout__notifications {
        position: relative;
        width: 100%;
        z-index: 5;
    }

    ::v-deep form > *:not(:first-child) {
        margin-top: 1.5rem;
    }

    @media screen and (min-width: 64em) {
        .app-layout__notifications {
            position: absolute;
            left: 50%;
            top: 0;
            max-width: 128rem;
            transform: translateX(-50%);
        }
    }

    @media screen and (min-width: 75em) {
        .app-layout {
            flex-direction: row;
        }

        .app-layout__header {
            display: none;
        }

        .app-layout__aside {
            width: 53rem;
        }
    }

    @media screen and (min-width: 82.5em) {
        .app-layout__notifications {
            position: absolute;
            left: 0;
            max-width: unset;
            transform: unset;
        }
    }

    @media screen and (min-width: 120em) {
        .app-layout__content {
            padding: 3rem 4rem;

            ::v-deep > div:not(:first-child) {
                margin-top: 2rem;
            }
        }

        ::v-deep form > *:not(:first-child) {
            margin-top: 2.5rem;
        }
    }
</style>
